export const formConfList = [
	{
		label: '主播名称',
		prop: 'username',
		type: 'input',
	},
	{
		label: '主播logo',
		prop: '2',
		type: 'solt',
	},
	{
		label: '直播平台',
		prop: 'pt',
		type: 'select',
		options: [
			{
				name: '淘宝',
				id: 1
			},
		]
	},
	{
		label: '擅长带货类目',
		prop: 'operation',
		type: 'solt',
	},
	{
		label: '主播标签',
		prop: 'bq',
		type: 'input',
	},
	{
		label: '手机号',
		prop: 'mobile',
		type: 'input',
	},
	{
		label: 'qq',
		prop: 'qq',
		type: 'input',
	},
	{
		label: '邮箱',
		prop: 'email',
		type: 'input',
	},
	{
		label: '佣金比例',
		prop: 'rate',
		type: 'input',
		append: '%'
	},
	{
		label: '服务费',
		prop: 'service',
		type: 'input',
		append: '元/品'
	},
	{
		label: '保证金',
		prop: 'bzj',
		type: 'input',
		append: '元'
	},
	{
		label: '服务领域',
		prop: 'ly',
		type: 'input',
	},
	{
		label: '自我介绍',
		prop: 'per_memo',
		type: 'input',
	},
	{
		label: '主播详情',
		prop: '9',
		type: 'solt',
	},
]

export const storeTableConf = [
	{
		label: '店铺地址',
		prop: 'shop_url'
	},
	{
		label: '店铺名称',
		prop: 'shop_name'
	},
	{
		label: '旺旺',
		prop: 'ww_nick'
	},
	{
		label: '授权码',
		prop: 'auth_token'
	},
	{
		label: '联系人',
		prop: 'username'
	},
	{
		label: '联系电话',
		prop: 'mobile'
	},
	{
		label: 'qq',
		prop: 'qq'
	},
	{
		label: '微信',
		prop: 'wx'
	},
	{
		label: '操作',
		prop: 'operation'
	}
]