<template>
	<div class="basicInfo">
		<div class="title">账号信息</div>
		<ul>
			<li class="flex_r f_ai_c pt10 pb 10">
				<span class="pl20 pr20">登录账号：</span>
				<span class="pl20 pr20">{{userInfo.mobile}}</span>
				<el-tag>主播端</el-tag>
			</li>
			<li class="flex_r f_ai_c pt10 pb 10">
				<span class="pl20 pr20">微信账号：{{userInfo.nickname}}</span>
			</li>
			<li class="flex_r f_ai_c pt10 pb 10">
				<span class="pl20 pr20">账号类型：</span>
				<span class="pl20 pr20" v-if="userInfo.pid">子账号</span>
				<span class="pl20 pr20" v-else>主账号</span>
			</li>
			<li class="flex_r f_ai_c pt10 pb 10">
				<span class="pl20 pr20">所属岗位：</span>
				<span class="pl20 pr20"  v-if="userInfo.group_name">{{userInfo.group_name}}</span>
				<span class="pl20 pr20">管理员</span>
			</li>
		</ul>
		<div class="title mt50 flex_r f_ai_c">
			<span>账号信息</span>
			<el-button class="ml20" type="primary" @click="disabled = false">编辑主播信息</el-button>
		</div>
		<div class="w75 mt30">
			<e-form ref="formRef" :formConfList="formConfList" :disabled="disabled">
				<template #2>
					<img v-if="disabled" :src="avatar" class="avatar">
					<el-upload
						v-else
						class="avatar-uploader"
						:action="uploadUrl"
						:show-file-list="false"
						:data="data"
						:on-success="handleAvatarSuccess">
						<img v-if="avatar" :src="avatar" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</template>
				<template #operation>
					<el-form-item label="">
						<el-select v-model="cat" placeholder="商品分类">
							<el-option :label="cate.name" :value="cate.id"  v-for="cate in category" :key="cate.index"></el-option>
						</el-select>
					</el-form-item>
				</template>
				<template #9>
					<editor ref="editorRef" />
				</template>
			</e-form>
			<div>
				<el-button type="primary" @click="confirm">保存</el-button>
				<el-button @click="disabled = true">取消</el-button>
			</div>
		</div>
		<e-dialog title="绑定微信" ref="dialogRef">
		</e-dialog>
	</div>
</template>

<script>
import {formConfList} from '@data/basicInfo';
export default {
	data() {
		return {
			disabled: true,
			formConfList,
			userInfo: {},
			category: {},
			cat: [],
			data: {},
			origin_url: '',
			uploadUrl: '',
			avatar: ''
		}
	},
	methods: {
		confirm(){
			let that = this
			let formData = that.$refs.formRef.formData
			this.post('?server=update.e.user.account.info',{
				username:formData.username,
				mobile:formData.mobile,
				qq:formData.qq,
				email:formData.email,
				memo: this.$refs.editorRef.content,
				per_memo: formData.per_memo,
				bq: formData.bq,
				rate: formData.rate,
				service: formData.service,
				bzj: formData.bzj,
				ly: formData.ly,
				cid: this.cat,
				avatar_url:this.origin_url}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		handleAvatarSuccess(response){
			if (response.code === 4003) {
				this.$message.error('您没有权限操作');

				return false;
			}
			if(response.code == 0){
				this.$message.error(response.msg);
				return
			}
			this.avatar = response.data
			this.$message.success(response.msg);
			this.avatar = response.data.url
			this.origin_url = response.data.origin_url
		},
		GetCategoryLists(){
			let that = this
			this.category = []
			this.get('?server=goods.category.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.category = response.data
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetUserInfo(){
			let that = this
			this.get('?server=auth.user.info.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				// that.userInfo.avatar = response.data.avatar
				// that.userInfo.username = response.data.username
				that.userInfo = response.data

			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetAuUserInfo(){
			let that = this
			this.get('?server=live.anchor.info.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.$refs.formRef.formData.username = response.data.username
				that.$refs.formRef.formData.service = response.data.service
				that.$refs.formRef.formData.bzj = response.data.bzj
				that.cat = response.data.cid
				that.$refs.formRef.formData.rate = response.data.rate
				that.$refs.formRef.formData.pt = response.data.pt
				that.$refs.editorRef.content = response.data.qd_memo
				that.origin_url = response.data.origin_url
				that.avatar = response.data.avatar

				that.$refs.formRef.formData.qq = response.data.qq
				that.$refs.formRef.formData.mobile = response.data.mobile
				that.$refs.formRef.formData.email = response.data.email
				that.$refs.formRef.formData.per_memo = response.data.per_memo
				that.$refs.formRef.formData.bq = response.data.bq
				that.$refs.formRef.formData.ly = response.data.ly
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			let token = localStorage.getItem('token')
			this.data = {"auth_token": token};
			this.uploadUrl = this.appConfig.api_request_url+'?server=upload.file'
			this.GetUserInfo()
			this.GetAuUserInfo()
			this.GetCategoryLists()
		});

	},
}
</script>

<style lang="scss" scoped>
.title {
	position: relative;
	line-height: 20px;
	padding-left: 20px;
	font-size: 16px;
}
.title:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 6px;
    height: 22px;
    border-radius: 3px;
    background: #fc2628;
}

</style>

<style lang="scss">
.basicInfo {
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	// .el-input.is-disabled .el-input__inner {
	// 	background-color: #fff;
	// 	border: none;
	// 	color: #000000;
	// 	cursor: text;
	// }
}
</style>